import React, { lazy, useEffect,/* useState*/ } from 'react';
import { Routes, Route, /*Navigate*/ } from 'react-router-dom';
import Layout from 'components/Layout';
import LazyRender from './hoc/LazyRender';
import 'css/styles.css';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

const Home = lazy(() => import('components/Layout/Home'));
const UserRegistrationModule = lazy(() => import('components/UserRegistration/UserRegistration'));
const ClaimsModule = lazy(() => import('components/Claims'));
const EligibityModule = lazy(() => import('components/Eligibility'));
const Authorizations = lazy(() => import('components/Authorizations'));
const ProviderDataRequest = lazy(() => import('components/ProviderDataRequest'));

const App = () => {

    useEffect(() => {
        ajax('api/Config/ConfigVariables')
            .pipe(map((response) => response.response))
            .subscribe((config) => {
                if (config === null || config === undefined) {
                    return;
                }
                var keyValue = config.segmentKey;
                if (keyValue === '' || keyValue === null) {
                    return;
                }
                const script = document.createElement('script');
                script.innerText = !function () {
                    var analytics = window.analytics = window.analytics || [];
                    if (!analytics.initialize)
                        if (analytics.invoked)
                            window.console && window.console.error && console.error('Segment snippet included twice.');
                        else {
                            analytics.invoked = !0;
                            analytics.methods = [
                                'trackSubmit',
                                'trackClick',
                                'trackLink',
                                'trackForm',
                                'pageview',
                                'identify',
                                'reset',
                                'group',
                                'track',
                                'ready',
                                'alias',
                                'debug',
                                'page',
                                'once',
                                'off',
                                'on',
                                'addSourceMiddleware',
                                'addIntegrationMiddleware',
                                'setAnonymousId',
                                'addDestinationMiddleware'
                            ];
                            analytics.factory = function (e) {
                                return function () {
                                    if (window.analytics.initialized)
                                        return window.analytics[e].apply(window.analytics, arguments);
                                    var i = Array.prototype.slice.call(arguments);
                                    i.unshift(e);
                                    analytics.push(i);
                                    return analytics;
                                };
                            };
                            for (var i = 0; i < analytics.methods.length; i++) {
                                var key = analytics.methods[i];
                                analytics[key] = analytics.factory(key);
                            }
                            analytics.load = function (key, i) {
                                var t = document.createElement('script');
                                t.type = 'text/javascript';
                                t.async = !0;
                                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                                var n = document.getElementsByTagName('script')[0];
                                n.parentNode.insertBefore(t, n);
                                analytics._loadOptions = i;
                            };
                            analytics._writeKey = keyValue;
                            analytics.SNIPPET_VERSION = '4.16.1';
                        }
                    analytics.load(keyValue);
                    analytics.page();
                }
                    ();

                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            });
    }, []);

    return (
        <Layout>
            <Routes>
                <Route exact path="/" element={LazyRender(Home)} />
                <Route path="/user-registration" element={LazyRender(UserRegistrationModule)} />
                <Route path="/check-claim-status" element={LazyRender(ClaimsModule)} />
                <Route path="/verify-eligibility" element={LazyRender(EligibityModule)} />
                <Route path="/authorizations" element={LazyRender(Authorizations)} />
                <Route path="/provider-data-update-request" element={LazyRender(ProviderDataRequest)} />
            </Routes>
        </Layout>
    );
};

export default App;
