import React from 'react';
import { nodeScriptReplace } from './ScriptUtil'

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        var get_HeaderGC = fetch('api/url?type=header');

        Promise.all([get_HeaderGC])
            .then((responses) =>
                Promise.all([responses[0].text()])
            )
            .then((data) => {
                const [headerHTML] = data;

                const containerHeader = document.getElementById('header-section');
                containerHeader.innerHTML = headerHTML;
                nodeScriptReplace(containerHeader);
            })
            .finally(() => {
                document.getElementById('header-section').style.display = 'block';
            })
    }

    render() {
        return (
            <div>
                <div id="header-section" style={{ display: 'block' }}></div>
            </div>
        );
    }
}
