import { Subject } from 'rxjs';
import { Notification } from '../interfaces/Notification';

const notificationSubject = new Subject();

const MESSAGELEVEL = {
    SUCCESS: 0,
    WARN: 1,
    ERROR: 2,
    CUSTOM: 3,
};

const notificationService = {
    success: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.SUCCESS }),
    warning: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.WARN }),
    error: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.ERROR }),
    custom: (notification: Notification) =>
        notificationSubject.next({ ...notification, messageLevel: MESSAGELEVEL.CUSTOM }),
    get: () => notificationSubject.asObservable(),
};

export { notificationService, MESSAGELEVEL };
