import React from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { notificationService, MESSAGELEVEL } from '../../subjects/NotificationService';

export default class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xPosition: '80%',
        }
    }
    componentDidMount() {
        this.subscription = notificationService.get().subscribe({
            next: ({ title, content, messageLevel, cssClass, width, timeOut }) => {
                this.toastInstance.title = title;
                this.toastInstance.content = content;
                if (width) {
                    this.toastInstance.width = width;
                    if (width > 300) {
                        const xPosition = window.innerWidth - width - 50;
                        this.setState({ xPosition });
                    }
                }
                if (timeOut) {
                    this.toastInstance.timeOut = timeOut;
                }
                switch (messageLevel) {
                    case MESSAGELEVEL.SUCCESS:
                        this.toastInstance.cssClass = 'e-toast-success';
                        break;
                    case MESSAGELEVEL.WARN:
                        this.toastInstance.cssClass = 'e-toast-warning';
                        break;
                    case MESSAGELEVEL.ERROR:
                        this.toastInstance.cssClass = 'e-toast-danger';
                        break;
                    case MESSAGELEVEL.CUSTOM:
                        this.toastInstance.cssClass = cssClass;
                        break;
                    default:
                        break;
                }
                this.toastInstance.show();
            },
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    render() {
        return (
            <ToastComponent
                ref={(toast) => (this.toastInstance = toast)}
                position={{ X: this.state.xPosition, Y: '10%' }}
            />
        );
    }
}
