import React from 'react';
import moment from 'moment';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    defaultFooter() {
        return (
            <footer className="main-footer" id="footer-section">
                <p>
                    Confidential patient information; Proprietary trade secret information. Unauthorized
                    use prohibited; All rights reserved by Alignment Healthcare.
                </p>
            </footer>
        );
    }

    componentDidMount() {
        var get_FooterGC = fetch('api/url?type=footer');
        var get_LastUpdate = fetch('api/url?type=lastUpdate');
        var get_MaterialId = fetch('api/url?type=materialId');

        Promise.all([get_FooterGC, get_LastUpdate, get_MaterialId])
            .then((responses) =>
                Promise.all([responses[0].text(), responses[1].text(), responses[2].text()])
            )
            .then((data) => {
                const [footerHTML, lastUpdate, materialId] = data;
                const container = document.getElementById('footer-section');
                container.innerHTML = footerHTML;

                const parseLastUpdateDate = (dateString) => dateString === '#{LAST_UPDATE_DATE}#' ? null : dateString;
                const lastUpdateDate = moment
                    .utc(parseLastUpdateDate(lastUpdate) || '2022-10-01T22:39:25Z')
                    .local()
                    .format('MM/DD/YYYY');

                const div = document.querySelector(".footer__bottom .grid-container");
                const span = document.createElement("span");
                const textnode = document.createTextNode("Page last updated: " + lastUpdateDate + " | " + materialId + "");
                span.appendChild(textnode);

                div.appendChild(span);
            })
            .catch(() => {
                const container = document.getElementById('footer-section');
                container.innerHTML = this.defaultFooter();
                container.style.display = 'block';
            })
    }

    render() {
        return (
            <div id="footer-section">
            </div>
        );
    }
}
