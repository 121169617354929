import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import '../NavMenu/NavMenu.css';
import Footer from './Footer';
import Header from './Header';
import Notification from '../Common/Notification';
import PopupWindow from '../Common/PopupWindow';
import GlobalDialog from 'components/Common/GlobalDialog';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
            featureFlags: null,
        };
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Header />
                    <div className="main-content">
                        <Container>{this.props.children}</Container>
                        <Notification />
                        <GlobalDialog />
                        <PopupWindow />
                    </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export { Layout as UnconnectedLayout };
export default connect()(Layout);
