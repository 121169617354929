import React from 'react';
import { faExpand, faMinus, faCompress } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import TSBaseComponent from 'common/TSBaseComponent';
import { Popup } from '../../interfaces/Popup';
import { popupWindowService } from '../../subjects/PopupWindowService';

export default class PopupWindow extends TSBaseComponent<any> {
    dialog: DialogComponent | null;
    state: {
        dialog: Popup | null;
        fullScreen: boolean;
        minimized: boolean;
    };

    constructor(prop) {
        super(prop);

        this.dialog = null;
        this.state = {
            dialog: null,
            fullScreen: false,
            minimized: false,
        };
    }

    componentDidMount() {
        this.subscriptions.push(
            popupWindowService.get().subscribe({
                next: (popup: any) => {
                    this.setState({
                        dialog: {
                            header: popup.header,
                            content: popup.content,
                            buttons: popup.buttons,
                            width: popup.width || '1000',
                            position: popup.position,
                            onClose: popup.onClose,
                            cssClass: popup.cssClass + ' ' || '',
                            disableCloseWithEscapeKey: popup.disableCloseWithEscapeKey || false,
                        },
                    });
                },
            })
        );

        this.subscriptions.push(
            popupWindowService.getClose().subscribe({
                next: this.onClose,
            })
        );
    }

    toggleMinimize = () => {
        if (!this.dialog) {
            return;
        }

        if (this.state.minimized) {
            this.dialog.height = 'auto';
            // this.dialog.width = undefined;
        } else {
            this.dialog.height = '69px';
            this.dialog.width = '300px';
        }

        this.setState({ minimized: !this.state.minimized, fullScreen: false });
    };

    toggleFullScreen = () => {
        if (!this.dialog) {
            return;
        }

        if (this.state.fullScreen) {
            if (this.dialog) {
                this.dialog.height = 'auto';
                // this.dialog.width = this.state.width;
            }
        } else {
            this.dialog.height = '100%';
            this.dialog.width = '100%';
        }

        this.setState({ fullScreen: !this.state.fullScreen, minimized: false });
    };

    getHeader = () => {
        return (
            <div>
                <span id="popup_header_text">{this.state.dialog?.header}</span>
                <button
                    type="button"
                    className="btn btn-box-tool box-header-button"
                    onClick={this.toggleMinimize}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
                <button
                    type="button"
                    className="btn btn-box-tool box-header-button"
                    onClick={this.toggleFullScreen}
                >
                    {this.state.fullScreen ? (
                        <FontAwesomeIcon icon={faCompress} />
                    ) : (
                        <FontAwesomeIcon icon={faExpand} />
                    )}
                </button>
            </div>
        );
    };

    onClose = () => {
        typeof this.state.dialog?.onClose === 'function' && this.state.dialog.onClose();
        this.setState({ dialog: null });
    };

    render() {
        return (
            <div>
                {this.state.dialog !== null && (
                    <DialogComponent
                        key="popupwindow"
                        ref={(dialog) => (this.dialog = dialog)}
                        showCloseIcon={true}
                        header={this.getHeader}
                        height={this.state.dialog.height}
                        width={this.state.dialog.width}
                        isModal={false}
                        position={this.state.dialog.position || { X: 'center', Y: 'center' }}
                        allowDragging={true}
                        enableResize={true}
                        visible={true}
                        buttons={this.state.dialog.buttons || [{}]}
                        close={() => popupWindowService.close()}
                        cssClass={`${this.state.dialog.cssClass}popup-window`}
                        zIndex={1100}
                        content={this.state.dialog.content.bind(this)}
                        closeOnEscape={this.state.dialog.disableCloseWithEscapeKey ? false : true}
                    />
                )}
            </div>
        );
    }
}
