import { ajax } from 'rxjs/ajax';

const ajaxCall = (url, method, responseType, headers, body) => {
    const ajaxCall$ = ajax({
        url,
        method,
        responseType,
        headers,
        body,
    });

    return ajaxCall$;
};

const ajaxGet = (url) => ajaxCall(url, 'GET', 'json');

const ajaxGetType = (url, responseType, headers) => ajaxCall(url, 'GET', responseType, headers);

const ajaxPost = (url, data, responseType = 'json', headers) =>
    ajaxCall(
        url,
        'POST',
        responseType,
        {
            'Content-Type': 'application/json',
            ...headers,
        },
        JSON.stringify(data)
    );

const ajaxPut = (url, data, responseType = 'json') =>
    ajaxCall(
        url,
        'PUT',
        responseType,
        {
            'Content-Type': 'application/json',
        },
        JSON.stringify(data)
    );

const ajaxPatch = (url, data, responseType = 'json') =>
    ajaxCall(
        url,
        'PATCH',
        responseType,
        {
            'Content-Type': 'application/json',
        },
        JSON.stringify(data)
    );

const ajaxDelete = (url) => ajaxCall(url, 'DELETE', 'json');

export { ajaxPost, ajaxGet, ajaxGetType, ajaxDelete, ajaxPut, ajaxPatch };
