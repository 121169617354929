import { ReplaySubject } from 'rxjs';
import { ajaxGet, ajaxGetType } from '../common/ajax';

const config$ = new ReplaySubject(1);

const configurationService = {
    get: () => config$.asObservable(),
    set: (config) => config$.next(config),
    getFeatureFlags$: (id) => ajaxGet(`api/config/GetFeatureFlags/${id}`),

    getAppSetting$: (id) => ajaxGetType(`api/config/GetConfig/${id}`, 'text'),

    getAminFlags$: () => ajaxGet(`api/admin/applications/`),

    getUMSetting$: (variableKey) => ajaxGet(`api/um/settings/${variableKey}`),
};

export { configurationService };
