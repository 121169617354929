import React from 'react';
import { Spinner } from 'react-bootstrap';

function LazyRender(Component, props) {
    return (
        <React.Suspense
            fallback={
                <Spinner
                    animation="border"
                    style={{
                        height: '50px',
                        width: '50px',
                        color: '#e3165b',
                        position: 'relative',
                        top: '40vh',
                        left: '50%',
                    }}
                />
            }
        >
            <Component {...props} />
        </React.Suspense>
    );
}
export default LazyRender;
